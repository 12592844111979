import React from "react";

const ContactOne = () => {
  return (
    <section className="contact_form_area">
      <div className="contact_form_width">
        <h2 className="contact_map_title text-center">
          We're Not Satisfied Until You're.
        </h2>
        <div id="map">
          <iframe
            title="template google map"
            alt="template google map"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3690.4350971802187!2d114.1975639760161!3d22.337194379660115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDIwJzEzLjkiTiAxMTTCsDEyJzAwLjUiRQ!5e0!3m2!1sen!2sin!4v1725866300569!5m2!1sen!2sin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default ContactOne;
