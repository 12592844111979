"use client";

import React, { useEffect, useState } from "react";
// import axios from "axios"; // Don't forget to import axios
import "./topBanner.css";
import Prop from "../../assets/images/travelbag-banner.jpg";
// import { HeadPop } from "src/data";
const TopBanner = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [data, setData] = useState(null); // Add state for data

  // To enable or disable the top continue banner
  const active = false;

  // Paste the link here to redirect the page
  const url_link = "https://www.google.com";

  const handleRedirect = () => {
    window.open(url_link, "_blank");
  };

  useEffect(() => {
    // Check if window is defined
    if (typeof window !== "undefined") {
      // Set initial window width
      setWindowWidth(window.innerWidth);

      // Define a resize handler
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Clean up the event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  // useEffect(() => {
  //   // Define a function to fetch data
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get("https://api.example.com/data");
  //       console.log(response);
  //       setData(response.data);
  //     } catch (err) {
  //       console.error(err.message);
  //     }
  //   };

  //   // Call the fetch function
  //   fetchData();
  // }, []);

  if (!active) return null;

  const getPadding = () => {
    if (windowWidth < 768) return "20px 50px"; // Small screens
    if (windowWidth < 991) return "20px 100px"; // Medium screens
    return "20px 200px"; // Large screens
  };
  console.log("Prop: ", Prop);

  const buttonStyles = {
    background: "#1eb034",
    border: "1px solid #1eb034",
    color: "#ffffff",
    padding: getPadding(),
    fontFamily: "DM Sans, sans-serif",
    fontOpticalSizing: "auto",
    fontWeight: 600,
    fontSize: "30px",
    fontStyle: "normal",
    cursor: "pointer",
    borderRadius: "1px",
    transition: "transform 0.3s ease, background-color 0.3s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: isHovered ? "scale(1.1)" : "scale(1)", // Apply scale transformation on hover
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className="topbanner_main"
      onClick={handleRedirect}
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        // background: "black",
        cursor: "pointer",
        backgroundImage: `url(${Prop})`, // Use url() with the image path
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div
        className="text_css"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <p style={{ color: "white", fontSize: "30px", margin: 0, padding: 0 }}>
          Please Click Here To Continue
        </p>

        <button
          style={buttonStyles}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          CLICK TO CONTINUE
          <div
            style={{
              fontSize: "40px",
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {">"}
          </div>
        </button>
      </div>
    </div>
  );
};

export default TopBanner;
