import React, { useState } from "react";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import Footer from "@/components/footer";
import SliderOne from "@/components/slider-one";
import FeatureOne from "@/components/feature-one";
import AboutOne from "@/components/about-one";
import ServiceOne from "@/components/service-one";
import CtaOne from "@/components/cta-one";
// import FunfactOne from "@/components/funfact-one";
import GalleryOne from "@/components/gallery-one";
// import TestimonialsOne from "@/components/testimonials-one";
// import SponsorOne from "@/components/sponsor-one";
import CtaTwo from "@/components/cta-two";
// import BlogOne from "@/components/blog-one";
import ContactOne from "@/components/contact-one";
import BlogTwo from "@/components/blog-two";
import { Helmet as Head } from "react-helmet";
import favIcon from "@/images/favicon.png";
import TopBanner from "@/components/TopBanner/topBanner";
// import { Router } from "@reach/router";
// import LoginPage from "./login";
// import AdminPage from "@/components/admin";

// import AdminPage from "./admin";

const HomeOne = () => {
  // Shared state for active and urlLink
  // const [active, setActive] = useState(false);
  // const [urlLink, setUrlLink] = useState("");

  return (
    <MenuContextProvider>
      <Layout PageTitle="Home Page">
        <Head key="favicon">
          <link rel="icon" href={favIcon} type="image/png" />
          <title>
            Get Your Space Pest-free with Our Tailored Solutions | Nifixeci
          </title>
          <meta
            name="description"
            content="Nifixeci provides efficient pest extermination solutions at the
            cheapest rate without compromising quality."
          />
        </Head>

        <TopBanner />
        {/* <Router>
          <LoginPage path="/login" />
          <AdminPage
            path="/admin"
            setActive={setActive}
            setUrlLink={setUrlLink}
          />
        </Router> */}

        <HeaderOne />
        <SliderOne />
        <FeatureOne />
        <AboutOne />
        <ServiceOne />
        <CtaOne />
        {/* <FunfactOne /> */}
        {/* <GalleryOne /> */}
        {/* <TestimonialsOne /> */}
        {/* <SponsorOne /> */}
        <CtaTwo />
        <BlogTwo />
        <ContactOne />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default HomeOne;
